import React from "react";
import Layout from "../components/Layout";
import Privacy from "../components/Legal/privacy";
import styled from "styled-components";
import tw from "tailwind.macro";

const Container = styled.div`
  ${tw` my-32`}
`


const PrivacyPolicy = () => {

  return <Layout>
    <Container>
    <Privacy />
    </Container>
  </Layout>

}

export default PrivacyPolicy